import React, { Component } from 'react';
import 'intl';
import { Button, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import Img from 'gatsby-image';

import ProductSection from './productSection';
import Testimonials from './testimonials';

import SocialLinks from './socialLinks';

import '../assets/scss/pages/_mediaPage.scss';

class MediaPage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { activeLangKey, intro, headerImage, testimonials, german, international } = this.props;

    return (
      <div className="media-wrapper">
        <section className="intro text-center">
          <div
            className="position-absolute social-links-wrapper"
          >
            <SocialLinks/>
          </div>
          <h1>
            <span>
              {intro.preHeader}
            </span>
            {intro.header}
          </h1>
          {headerImage ? (
            <Img
              className='img-fluid'
              style={{ position: 'initial' }}
              fixed={headerImage}
            />
          ) : null}
        </section>


        <section className="media-section first oblique">
          <Container
            className="oblique-content"
          >
            <Row>
              <Col lg={12}>
                <h3>
                <span>
                  {german.preHeader}
                </span>
                  {german.header}
                </h3>
              </Col>
              {german.items.map(row => (
                <Col className='mt-4' lg={4}>
                  <Card className="text-center">
                    <CardHeader>
                      {row.published ? <><i className='far fa-clock'/> <span>{row.published}</span></> : null}
                    </CardHeader>
                    <CardBody>
                      <CardTitle>
                        {row.header ? row.header : null}
                      </CardTitle>
                    </CardBody>
                    <CardFooter>
                      {row.authorName ? <p><i className='fas fa-globe-americas'/> <span>{row.authorName}</span></p> : null}

                      <Button
                        tag="a"
                        href={row.link}
                        target="_blank"
                      >Read more</Button>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <section className="media-section">
          <Container
            className='pl-0 pr-0'
          >
            <Row>
              <Col lg={12}>
                <h3>
                <span>
                  {international.preHeader}
                </span>
                  {international.header}
                </h3>
              </Col>
              {international.items.map(row => (
                <Col className='mt-4' lg={4}>
                  <Card className="text-center">
                    <CardHeader>
                      {row.published ? <><i className='far fa-clock'/> <span>{row.published}</span></> : null}
                    </CardHeader>
                    <CardBody>
                      <CardTitle>
                        {row.header ? row.header : null}
                      </CardTitle>
                    </CardBody>
                    <CardFooter>
                      {row.authorName ? <p><i className='fas fa-globe-americas'/> <span>{row.authorName}</span></p> : null}

                      <Button
                        tag="a"
                        href={row.link}
                        target="_blank"
                      >Read more</Button>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <ProductSection
          activeLangKey={activeLangKey}
        />

        {/*<Testimonials
          activeLangKey={activeLangKey}
          items={testimonials}
        />*/}
      </div>
    );
  }
}

export default MediaPage;
